.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table th:last-child {
  display: none;
}

.table td {
  text-align: left;
  border-top: 1px solid #bdbdbd;
  padding: 8px 0;
}

.table td:last-child {
  text-align: right;
}

.table__filter {
  cursor: pointer;
  margin-left: 10px;
}

.table__filter-list {
  margin: 0;
  list-style: none;
  border: 1px solid #797979;
  border-radius: 5px;
  padding: 0;
}
.table__filter-list-item:first-of-type {
  padding-top: 8px;
}
.table__filter-list-item:last-of-type {
  padding-bottom: 8px;
}
.table__filter-list-item {
  padding: 2px 4px;
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
}
.table__filter-list-item_selected, .table__filter-list-item:hover {
  background: #F2F2F2;
}

.table_auto_width tr td {
  width: auto;
  padding: 8px;
  word-break: break-word;
}
.table_auto_width tr > td:first-child {
  width: 5%;
}
.table_auto_width tr > td:nth-child(4) {
  width: 40%;
}
