.preloader {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background: #2c2d44;
  background-image: -webkit-linear-gradient(45deg, #3f3251 2%, #002025 100%);
  background-image: linear-gradient(45deg, #3f3251 2%, #002025 100%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader__title {
  color: #fff;
  font-size: 30px;
  /*opacity: 075;*/
  /*animation: pulse 2s linear infinite;*/
  font-weight: bold;
  margin-bottom: 30px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 0.25;
  }

  50% {
    transform: scale(1.2, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0.25;
  }
}
