*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-size: 1rem;
}

body {
  margin: 0;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: "Roboto Regular", sans-serif;
}

.responsive-img {
  display: block;
  max-width: 100%;
  height: auto;
}

main {
  flex-grow: 1;
  position: relative;
}

.hidden {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

a {
  text-decoration: none;
}

/* base elements */
.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-inline {
  display: inline-flex;
}

.flex--jc-center {
  justify-content: center;
}

.flex--ac {
  align-items: center;
}

.flex--sb {
  justify-content: space-between;
}

h2 {
  margin-top: 0;
  font-size: 24px;
  font-weight: normal;
}

.flex-wrap > div:first-child {
  margin-bottom: 1em;
}
