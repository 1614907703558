.form-group {
    margin-bottom: 15px;
    &__label {
        margin-bottom: 8px;
    }
}

.poll {
    &__answers {
        &__answer {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            &__field {
                width: 400px;
            }
            &__action-button {
                padding-left: 10px;
            }
            &__voices {
                white-space: nowrap;
                padding-left: 10px;
            }
        }
    }
}
