/* input */
.control {
  background: var(--bg, #fff);
  border: 1px solid var(--border, #ced4da);
  font-size: 15px;
  padding: var(--pd, 10px);
  width: 100%;
}

.control:focus {
  border-color: #7f7a7a;
  outline: none;
}

.control--custom {
  --bg: #f4f4f4;
  --pd: 15px;
  --border: #f4f4f4;
}

.control--s {
  --bg: #fcfcfc;
  --border: rgba(0, 0, 0, 0.1);
}

/* input end */

.form textarea {
  height: 200px;
}

/* label */
.form .form__title {
  width: 170px;
  margin-right: 10px;
}

.form__s-title {
  font-weight: 300;
}

.title-mb {
  margin-bottom: 7px;
  display: block;
}

/* label end */

/* form__item */
.form__item {
  position: relative;
}

.form__item-mb {
  margin-bottom: 15px;
}

/* form__item end */

.form__border {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 11px;
  padding-bottom: 11px;
}

.form__foot {
  padding-top: 15px;
}

.form__item--min {
  width: 400px;
}

.form__item--all {
  width: calc(100% - 180px);
}

.red-txt {
  font-size: 14px;
  color: var(--red);
}

.form .red-txt {
  margin-top: 5px;
  text-align: left;
}

.form__txt {
  text-align: right;
}

.fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.fieldset-mt {
  margin-top: 20px;
}

.fieldset > legend {
  margin-bottom: 15px;
}

.fieldset .form__title {
  margin-bottom: 10px;
}

.card--custom .form__border:last-child {
  border-bottom: none;
}

.bg-left, .bg-right , .bg-top, .bg-bottom {
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: auto;
}

.bg-left {
  background-image: url("../../img/icon/left.png");
}

.bg-right {
  background-image: url("../../img/icon/right.png");
}

.bg-top {
  background-image: url("../../img/icon/top.png");
}

.bg-bottom {
  background-image: url("../../img/icon/bottom.png");
}

.radio-wrapper input {
  display: none !important;
}
.radio-wrapper label {
  width: 90px;
  height: 90px;
}
.radio-wrapper label {
  border: 1px solid #D9D9D9;
}
.radio-wrapper input:checked + label {
  border: 1px solid var(--green);
}

.form-inner .form__title{
  width: 25%;
}

.delete--btn {
  position: absolute;
  z-index: 2;
  right: 1em;
  top: 1em;
  cursor: pointer;
}

.form--basis-flex {
  flex-wrap: nowrap;
}
.form--basis-flex .form__item{
  margin-right: 2em;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.form--basis-flex .form__item .top-text{
  min-width: 170px;
}
.form--basis-flex .form__item > label{
  min-width: 215px;
}
.form--basis-flex .form__item .top-text, .form--basis-flex .form__item > label{
  flex: .5 1;
}

.formFlat .form__item, .formFlat .form--basis-flex,  .formFlat .form__item > div{
  min-width: 200px;
}

/*Multi Fields*/
.wrapperMulti {
  border-spacing: 0;
  vertical-align: middle;
}
.wrapperMulti tr td,  .wrapperMulti tr th {
  border-bottom: 1px solid grey;
  padding: .5em;
}
.btnT {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: .5em;
}
.deleteInner {
  background-color: var(--red);
}
.addInner {
  background-color: var(--green);
}
.wrapperMulti tfoot tr td {
  border-bottom: none;
}

.street-w .flex > *{
  flex: 1 1;
}

/**/
