.side-menu__head {
  padding: 20px;
  display: block;
  text-align: center;
}

.menu__link {
  position: relative;
  color: #fff;
  width: 100%;
  padding: 10px 20px;
  display: block;
  background: var(--menu, rgba(242, 242, 242, 0.1));
  text-align: left;
}

.menu__link--btn {
  border: none;
  font-size: 16px;
}

.menu__link.active,
.menu__link:hover {
  background: var(--menu-ch, rgba(132, 194, 37, 0.1));
}

/*.menu__link.active {*/
/*  background: var(--active-menu, rgb(242, 242, 242));*/
/*}*/

.menu__link--green {
  --menu: rgba(132, 194, 37, 0.3);
  --menu-ch: var(--green);
}

.menu__link--orange {
  --menu: rgba(246, 184, 0, 0.3);
  --menu-ch: var(--orange);
}

.menu__item + .menu__item {
  margin-top: 8px;
}
