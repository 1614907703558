.feature__item {
  margin-bottom: 10px;
}

.feature__sub {
  width: 49%;
}

.feature__sub .css-1qclg2v-control,
.feature__sub .css-e2qbnx-control {
  min-height: 50px;
}

.feature__sub--bg {
  background: var(--bg, #f4f4f4);
  padding: 10px;
}

.feature__sub--dbg {
  background: #ebebeb;
  padding: 10px 40px;
}

.feature-mr {
  margin-right: 15px;
}

.feature__sub-item {
  flex: 1;
}
