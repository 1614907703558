@import-normalize;
@import "_var.css";
@import "common/base.css";
@import "common/layout.css";
@import "common/card.css";
@import "common/list.css";
@import "common/menu.css";
@import "common/upload.css";
@import "common/button.css";
@import "common/canvas.css";
@import "common/form.css";
@import "common/checbox-radio.css";
@import "common/table.css";
@import "page/page.css";
@import "common/dropzone.css";
@import "common/header.css";
@import "common/modal.css";
@import "common/feature.css";
@import "common/box.css";
@import "common/toolbar.css";
@import "page/login.css";
@import "common/gallery.css";
@import "common/preloader.css";
@import "common/pagination.css";

@media screen and (max-width: 1366px) {
  main {
    width: 100%;
    margin: 20px;
  }

  .side-menu {
    display: none;
  }

  .right-list {
    display: none;
  }

  .frame {
    width: 100%;
  }
}

