.auth-page {
  background: #f6f6f6;
  min-height: 100vh;
  justify-content: center;
}

.form-auth {
  background: #fff;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 30px;
  text-align: center;
}

.form-auth .form__body {
  padding-top: 25px;
}

.form-auth .form__item {
  margin-bottom: 15px;
}

.form-auth button {
  margin-top: 10px;
}
