.gallery {
  margin: 0 -10px;
}

.gallery .btn-del {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease;
}

.gallery__thumbnail {
  margin: 5px 10px;
  position: relative;
}

.gallery__thumbnail img {
  width: 120px;
  height: 100px;
  object-fit: cover;
  display: block;
  border-radius: 6px;
}

.gallery .open {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  transform: translateY(-50%) translateX(-50%);
}

.gallery__thumbnail:hover .btn-del {
  opacity: 1;
  visibility: visible;
}
