.side-menu {
  background: var(--black);
  width: 340px;
}

main {
  width: calc(100% - 440px);
  margin: 40px 50px;
}

.wrapper {
  min-height: 100vh;
}

/* card end  */
.dark {
  color: #4f5256;
}

.title-medium {
  font-size: 20px;
}

.x-medium {
  font-size: 18px;
}

.tiny {
  font-size: 13px;
}

.block {
  display: block;
}

.min-mb,
.ling-group--mb {
  margin-bottom: 20px;
}

.ling-group .btn,
.ling-group .form {
  display: inline-flex;
  vertical-align: middle;
}

.mt-none {
  margin-top: 0;
}

.mb-none {
  margin-bottom: 0;
}

.title-bold {
  font-weight: bold;
}

.tab-content {
  padding: 10px 0;
}
