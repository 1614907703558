button {
  cursor: pointer;
}

.btn {
  font-size: 15px;
  padding: 10px 15px;
  transition: all 0.3s ease;
  border-radius: var(--br, 4px);
  background: var(--bg-color, #f2f2f2);
}

.btn--green {
  --bg-color: var(--green);

  color: #fff;
}

.btn--blue {
  --bg-color: #0093dd;

  color: #fff;
}

.btn--red {
  --bg-color: #da251d;

  color: #fff;
}

.btn--yellow {
  --bg-color: #F6B800;

  color: #fff;
}

.btn--radius {
  --br: 15px;
}

.btn--no-border {
  border: none;
}

.btn--border {
  border: 1px solid #eceff1;
  color: #2a2b2d;
}

.btn--border:hover {
  background: var(--green);
  color: #fff;
}

.btn:hover .trnswhite path {
  fill: #fff;
}

button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.item-indent {
  margin-right: 14px;
}

.button-group .btn {
  margin-right: 25px;
}

.btn--mw {
  min-width: 167px;
}

.btn--max {
  width: 100%;
}

.btn-reset {
  border: none;
  background: transparent;
}

.btn-option {
  padding: 2px 8px;
  display: inline-flex;
  width: 40px;
}

.btn-text {
  color: #da251d;
  text-decoration: underline;
}

.search__btn {
  position: absolute;
  right: 0;
  height: 50px;
  width: 50px;
  padding: 0;
}

.btn--secondary {
  border-radius: 0;
  width: 250px;
  font-size: 16px;
}

.btn--center {
  margin: 0 auto;
  display: block;
}

.float--right {
  float: right;
}

.icon--btn svg {
  margin-right: 15px;
}
