.upload {
  position: relative;
  max-width: 220px;
  -webkit-appearance: none;
}

input[type="file"] {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.item {
  position: relative;
}

.frame {
  width: calc(100% - 315px);
  background: #e0f5c0;
}

.frame__text {
  color: #4f5256;
  text-align: center;
  max-width: 190px;
}

.preview {
  position: relative;
  width: 330px;
  height: 250px;
  background: #e0f5c0;
}

.preview__view {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview--min {
  width: 250px;
}
