.modal {
  text-align: center;
}

.modal__title {
  font-size: 18px;
  padding: 13px 0;
}

.modal button {
  width: 115px;
}
