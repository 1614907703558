.context-menu {
  width: 90px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
}

.context-menu button {
  width: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.frame-canvas {
  position: relative;
  width: 100%;
}

.tooltip {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  max-width: 204px;
  padding: 10px;
  width: 100%;
  text-align: center;
}

.zoom-container {
  position: absolute;
  top: 10px;
  left: 10px;
}

.zoom-container button {
  width: 34px;
  height: 34px;
  background: var(--green);
  display: block;
  border: 1px solid #f2f2f2;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}
