.form--basis .form__group .form__item {
  width: 280px;
  margin-right: 17px;
}

.form--description .form__item-mr {
  margin-right: 10px;
}

.form--description .form__group {
  max-width: 600px;
}

.form--description .form__group-top {
  padding-bottom: 15px;
}

.form--description .form__group-bottom .form__item,
.form--description .form__group-top .form__item + .form__item {
  flex: 1;
}

.count {
  background-color: #AA4526;
  float: right;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
}

/* card-polling */
.card-polling {
  max-width: 1057px;
}

.field-wrap {
  margin-bottom: 30px;
}

.field-group {
  width: 100%;
  margin-top: 15px;
}

.field-group .form__item {
  width: calc((100% / 3) - 40px);
  margin: 0 10px;
}

.field-group .btn-option {
  height: 30px;
}

.card-create .btn {
  position: absolute;
  right: 23px;
  z-index: 999;
  top: 20px;
}

/* page feedback */
.form-feedback .form-info {
  background: #e0f5c0;
  padding: 10px 15px;
}

.form-feedback__head,
.form-feedback__body {
  margin-bottom: 18px;
}

.form-feedback .form__control {
  margin-bottom: 13px;
}

.form-feedback .form__title {
  width: auto;
}

.form-feedback__head .form__item + .form__item {
  margin-left: 35px;
}

.form-feedback .form-content {
  background: #f4f4f4;
  padding: 10px;
}

.manager-control {
  margin-top: 25px;
}

.form--loading {
  opacity: 0.8;
  pointer-events: none;
}

/*page complex*/
.houseSocial {
  display: flex;
  align-items: center;
  padding: 1em 0;
}

.houseSocial_col {
  color: var(--black);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  flex: .1 1;
  font-size: 16px;
  cursor: pointer;
}

/*end complex*/
