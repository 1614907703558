.background-settings {
    margin-top: 30px;
    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -15px;
    }
    &__col {
        padding: 0 15px;
        width: 360px;
    }
    &__form-group {
        margin-bottom: 20px;
        &__label {
            margin-bottom: 10px;
        }
        &__checkbox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            label {
                margin: 5px 0 0 10px;
            }
        }
    }
}
