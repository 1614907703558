.thumb,
.dropzone {
  width: 130px;
  height: 130px;
  margin: 0 10px;
  position: relative;
}

.dropzone {
  background: #e0f5c0;
  cursor: pointer;
}

.dropzone-group {
  margin-left: -10px;
}

.thumb__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumb .btn-option,
.gallery .btn-del {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--red);
  width: 30px;
  height: 30px;
}

.thumb .btn-option path,
.gallery .btn-del path {
  stroke: #fff;
}

