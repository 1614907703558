/* card */
.card {
  background: #fff;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
  padding: 15px 20px;
  position: relative;
}

.card--width {
  min-width: 450px;
  display: inline-block;
}

.card__head {
  text-align: right;
}

.card--md,
.card__head--mb {
  margin-bottom: 20px;
}

.card__head--border {
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 10px 0;
}

.card-content {
  flex: 1;
}

.card-content + .card-content {
  margin-left: 20px;
}

.card--custom .form__control--border {
  border: none;
}
