.toolbar {
  background: #3d3f4b;
  color: #fff;
  font-weight: bold;
  min-width: 175px;
  max-width: 220px;
  padding: 14px;
  border-radius: 15px;
  text-align: center;
  line-height: 22px;
  position: relative;
  margin: 0 20px;
}

.toolbar span {
  display: block;
}

.toolbar span:last-child {
  margin-top: 8px;
}

.toolbar::before {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 18px;
  border-style: solid;
  border-color: #3d3f4b transparent transparent transparent;
}
