input[type='radio'],
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
}

input[type="checkbox"] {
  background: var(--b-checkbox, #fff);
  width: 40px;
  height: 35px;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  border: 1px solid #c7c7c7;
}

input[type="radio"] {
  background: #fff;
  width: 20px;
  height: 20px;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  border: 2px solid #000;
  border-radius: 50%;
}

input[type="checkbox"]::after,
input[type="radio"]::after {
  content: '';
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  opacity: var(--o, 0);
  transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
}

input[type="checkbox"]::after {
  width: 10px;
  height: 14px;
  border: 2px solid var(--check-color, #000);
  border-top: 0;
  border-left: 0;
  left: 13px;
  top: 8px;
  transform: rotate(var(--rotate, 20deg));
}

input[type="radio"]::after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transform: scale(var(--s, 0.7));
  background: #000;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  --d-o: 0.3s;
  --b-checkbox: var(--green);
  --rotate: 43deg;
  --s: 43deg;
  --o: 1;
}

input[type="checkbox"].check-custom:checked {
  --b-checkbox: #000;
  --check-color: #fff;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
}

input[type='checkbox']:hover {
  border-color: var(--green);
}

input[type='radio']:hover {
  border-color: #4f4f4f;
}

input[type='radio'] + label {
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-left: 4px;
}
