.box {
  border: 1px solid #cfcfcf;
  padding: 15px 18px;
  color: #3d3f4b;
  position: relative;
}

.box .btn-del {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.box-status-0 {
  background: #e0f5c0;
}

.box__head {
  margin-bottom: 18px;
}

.box__head .check-custom {
  margin-right: 12px;
}

.box__head-top {
  margin-bottom: 10px;
}

.box li {
  margin-right: 30px;
}

.box li button {
  margin-left: 15px;
  padding: 4px 17px;
}
