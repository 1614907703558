.list-none {
  list-style: none;
  margin: 0;
  padding: 0;
}

.right-list {
  flex-basis: 315px;
  background: #3f3d3d;
}

.right-list__head {
  color: #fff;
  padding: 10px;
}

.right-list__first {
  flex-basis: 25%;
}

.right-list__button {
  margin-left: auto;
}

.right-list__body {
  margin-right: 9px;
}

.list {
  background: #fff;
  border-bottom: 1px solid #bdbdbd;
  padding: 10px;
}

.list__name {
  color: #000;
  text-decoration: underline;
}

/* ul */
.list-sub__item {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0;
}

.list-sub__link {
  color: #000;
  margin-right: 20px;
}

.list-sub__link:hover {
  color: var(--red);
}

