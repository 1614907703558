.pagination__wrap {
  position: relative;
}
.pagination__count {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.pagination__item {
  margin: 0 7px;
}

.pagination__link {
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg, #f2f2f2)
}

.pagination__link:hover {
  --bg: #e0f5c0
}

.pagination__item--active .pagination__link {
  --bg: var(--green);
  color: #fff
}

/* .pagination__item--option .pagination__link {
  width: auto
} */
